// ======================
// MigrateOpsDocsView
// ======================

import React, { ComponentType, useState } from "react";
import { alpha, Box, Button, Card, Chip, IconButton, Link, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
    getOperationRecipeDescription,
    getOperationRecipeLabel,
    useNavgiateToRecipeBuilder,
    useNavigateToOpDocs,
    useNavigateToOpInstructions,
    useNavigatetoRecipeReference,
} from "./MigrateOpsCommon";
import { CodeCard, DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { MdExpandLess, MdExpandMore, MdOutlineCloud, MdOutlineListAlt } from "react-icons/md";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import { LightDivider } from "../../common/misc";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import ProtoDoc from "../../assets/migrateOps/migrateops-proto.json";
import { ConfigField, ConfigFieldMap, getCurrentRecipeConfigFieldMap } from "./MigrateOpsGeneratedProtobufJsonHelpers";
import { SiAmazonaws, SiMicrosoft, SiMicrosoftazure, SiNutanix } from "react-icons/si";
import { AiOutlineCluster } from "react-icons/ai";
import { TbDatabaseExport, TbReportAnalytics } from "react-icons/tb";
import { BackButton } from "../../common/CommonButtons";
import { Route, Routes, useParams } from "react-router-dom";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import Ovirt from "../../assets/integration_logos/ovirt-white-icon.png";
import { useIsDesktop } from "../layout/MainLayout";
import { getExampleByRecipe } from "./MigrateOpsDocsExamples";
import { MigrateOpsGettingStartedInstructionsView } from "./MigrateOpsGettingStartedInstructionsView";
import { MIGRATE_OPS_DOCS_SUBROUTE } from "../app/AppRoutes";

interface MigrateOpsDocsViewProps {}

export const MigrateOpsDocsView: React.FC<MigrateOpsDocsViewProps> = (p) => {
    return (
        <Routes>
            <Route
                index
                element={
                    <ScreenContainer>
                        <ScreenTitleBar title={"MigrateOps Recipes"} />
                        <RecipeSelectionSection />
                    </ScreenContainer>
                }
            />
            <Route path={":recipeId"} element={<MigrateOpsRecipeView />} />
            <Route path={`${MIGRATE_OPS_DOCS_SUBROUTE.GETTING_STARTED}/:recipeId`} element={<MigrateOpsGettingStartedInstructionsView />} />
        </Routes>
    );
};

// ======================
// RecipeSelectionCardGroup
// ======================

interface RecipeSelectionSectionProps {}

export const RecipeSelectionSection: React.FC<RecipeSelectionSectionProps> = (p) => {
    const computeRecipes = RECIPE_CONFIGS.filter((r) => r.category === OperationRecipeCategory.COMPUTE).map((r) => r.recipeId);
    const localRecipes = RECIPE_CONFIGS.filter((r) => r.category === OperationRecipeCategory.LOCAL).map((r) => r.recipeId);
    const otherRecipes = RECIPE_CONFIGS.filter((r) => r.category === OperationRecipeCategory.OTHER).map((r) => r.recipeId);
    const openHelpArticle = useOpenHelpArticle();
    const articleLink = <Link onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS_CONFIG)}>{"click here"}</Link>;
    const goToRecipeReference = useNavigatetoRecipeReference();
    const isDesktop = useIsDesktop();
    const recipesWithBuilders = RECIPE_CONFIGS.filter((r) => r.hasBuilder).map((r) => r.recipeId);

    const goToRecipeBuilder = useNavgiateToRecipeBuilder();
    const goToInstructions = useNavigateToOpInstructions();
    const createRecipeGroup = (recipeIds: number[]) => {
        return (
            <Box pb={2} pt={1}>
                {Object.keys(OperationRecipeID)
                    .filter((key) => {
                        return recipeIds.includes(OperationRecipeID[key as keyof typeof OperationRecipeID]);
                    })
                    .map((key) => {
                        const recipeId = OperationRecipeID[key as keyof typeof OperationRecipeID];
                        const recipeConfig = getRecipeConfig(recipeId);
                        const recipeName = recipeConfig.recipeLabel;
                        const recipeDescription = recipeConfig.description;
                        return (
                            <Card
                                elevation={0}
                                sx={{
                                    marginBottom: 2,
                                }}
                            >
                                <Grid container alignItems={"center"} rowSpacing={1} p={2}>
                                    <Grid
                                        size={{
                                            xs: 12,
                                            md: 6,
                                        }}
                                    >
                                        <Stack spacing={4} direction={"row"} p={1} alignItems={"center"}>
                                            <Box width={24}>{recipeConfig.icon}</Box>
                                            <Stack>
                                                <Typography variant={"h6"}>{recipeName}</Typography>
                                                <Typography color={"textSecondary"} variant={"subtitle2"}>
                                                    {recipeDescription}
                                                </Typography>
                                                <Link
                                                    onClick={() => {
                                                        goToRecipeReference(recipeId);
                                                    }}
                                                >
                                                    {"View Reference"}
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        size={{
                                            xs: 12,
                                            md: 6,
                                        }}
                                    >
                                        <Stack pb={1} width={"100%"} direction={"row"} spacing={1} justifyContent={isDesktop ? "flex-end" : "center"}>
                                            {recipesWithBuilders.includes(recipeId) && (
                                                <Button
                                                    disableElevation
                                                    variant={"outlined"}
                                                    color={"neutral"}
                                                    onClick={() => {
                                                        goToRecipeBuilder(recipeId);
                                                    }}
                                                >
                                                    {"Use Interactive Guide"}
                                                </Button>
                                            )}
                                            <Button disableElevation variant={"contained"} color={"secondary"} onClick={() => goToInstructions(recipeId)}>
                                                {"Get Started"}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Card>
                        );
                    })}
            </Box>
        );
    };

    return (
        <Box>
            <Box pb={2}>
                <Typography>
                    {`The following are customizable MigrateOps automation recipes available in this project. 
                            Select a recipe below to get started or `}{" "}
                    {articleLink} {` to learn more.`}
                </Typography>
            </Box>
            <Typography variant={"h5"} fontWeight={600}>
                {"Compute Migration"}
            </Typography>
            {createRecipeGroup(computeRecipes)}
            <Typography variant={"h5"} fontWeight={600}>
                {"Local Migration"}
            </Typography>
            {createRecipeGroup(localRecipes)}
            <Typography variant={"h5"} fontWeight={600}>
                {"Other"}
            </Typography>
            {createRecipeGroup(otherRecipes)}
        </Box>
    );
};

// ======================
// MigrateOpsRecipeView
// ======================

interface MigrateOpsRecipeViewProps {}

export const MigrateOpsRecipeView: React.FC<MigrateOpsRecipeViewProps> = (p) => {
    const goBack = useNavigateToOpDocs();
    return (
        <ScreenContainer>
            <Box>
                <BackButton
                    navFunction={() => {
                        goBack();
                    }}
                    label={"Back to MigrateOps Recipes"}
                />
                <Typography variant={"h3"}>{"MigrateOps Configuration Reference"}</Typography>
                <Typography>{`The fields described below can be used in your MigrateOps configuration for this recipe.`}</Typography>
                <ReferenceCard />
            </Box>
        </ScreenContainer>
    );
};

// ======================
// ReferenceCard
// ======================
export const ReferenceCard: React.FC = (p) => {
    const { recipeId } = useParams();
    const recipeConfig = getRecipeConfig(Number(recipeId));
    const operationConfigJson = ProtoDoc.files
        .find((f) => f.name === "galaxycompletepb/operationpb/operation.proto")
        .messages.find((m) => m.name === "OperationConfig");

    const currentRecipeConfigFieldMap = getCurrentRecipeConfigFieldMap(ProtoDoc, operationConfigJson, recipeConfig.recipeKey);
    return (
        <>
            <Box pb={1} pt={2}>
                <Typography variant={"h5"} fontWeight={600}>
                    {recipeConfig.recipeLabel}
                </Typography>
                <Typography>{recipeConfig.description}</Typography>
                <Stack direction={"row"} spacing={1} pb={1} pt={1}>
                    <Chip
                        label={
                            <Typography sx={{ fontFamily: "monospace" }} fontWeight={600}>
                                {"Recipe ID: "}
                                {recipeConfig.recipeKey}
                            </Typography>
                        }
                        size={"small"}
                        sx={{ borderRadius: 1 }}
                    />
                </Stack>
                <Box pt={1}>
                    <Typography>
                        {`🔗 `}
                        <Link href={"#examples"}>{"View Example Config File"}</Link>
                    </Typography>
                </Box>
            </Box>
            <Stack direction={"row"} spacing={1} pb={2}></Stack>
            <DarkFlatOutlinedCard>
                {Object.keys(currentRecipeConfigFieldMap).map((key, index) => {
                    return <ConfigFieldRow index={index} keyName={key} key={key} configFieldMap={currentRecipeConfigFieldMap} />;
                })}
            </DarkFlatOutlinedCard>
            <Box id={"examples"} pb={2} />
            <Stack spacing={2}>
                <Typography variant={"h5"}>{"Example Config File"}</Typography>
                <CodeCard>
                    <Box p={2}>
                        <pre>{getExampleByRecipe(Number(recipeId))}</pre>
                    </Box>
                </CodeCard>
            </Stack>
        </>
    );
};

// ======================
// ConfigFieldRow
// ======================

interface ConfigFieldRowProps {
    keyName: string;
    configFieldMap: ConfigFieldMap;
    index?: number;
    oneOf?: boolean;
}

export const ConfigFieldRow: React.FC<ConfigFieldRowProps> = (p) => {
    const { keyName, configFieldMap, index, oneOf } = p;
    const [expanded, setExpanded] = useState(false);
    const configField = configFieldMap[keyName];
    const theme = useTheme();
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const formatDataType = (configField: ConfigField) => {
        if (configField.dataType === "oneOf") {
            return "one of";
        }
        if (configField.dataType === "object" && !!configField.definitionName) {
            return `object (${configField.definitionName})`;
        }

        if (!!configField.objectValueType) {
            return `object (key: string, value:${configField.objectValueType})`;
        }
        if (configField.dataType === "string" && !!configField.enum) {
            return `string (enum)`;
        }
        if (configField.dataType === "timestamp") {
            return "string (RFC3339 format, e.g. 2019-10-12T07:20:50.52Z)";
        }
        if (configField.dataType === "duration") {
            return "string (duration format in [s | ms | us], e.g. 30s)";
        }
        if (configField.dataType === "array") {
            if (!!configField.arrayItemDefinitionName) {
                return `array of objects (${configField.arrayItemDefinitionName})`;
            }
            return `array of ${configField.arrayType}s`;
        }
        return configField.dataType;
    };

    const shouldExpand = configField?.oneOfFields || configField?.properties || configField?.arrayType === "object";
    const renderExpandedContent = () => {
        if (configField.dataType === "oneOf") {
            return (
                <Box pt={2}>
                    <Box
                        sx={{
                            border: `1.5px solid ${theme.palette.primary.light}`,
                            margin: 1,
                            padding: 0,
                            background: alpha(theme.palette.primary.light, 0.07),
                            borderRadius: 3,
                        }}
                    >
                        <Box pr={1} pl={1} pt={2}>
                            <Typography fontWeight={600}>{"Note: only one of the following fields in this group can be specified at once."}</Typography>
                        </Box>
                        {Object.keys(configField.oneOfFields).map((key, index) => {
                            return <ConfigFieldRow index={index} keyName={key} key={key} configFieldMap={configField.oneOfFields} oneOf={true} />;
                        })}
                    </Box>
                </Box>
            );
        }
        if (!!configField?.properties) {
            return (
                <DarkFlatOutlinedCard>
                    <Box pr={2} pl={2} pt={2}>
                        <Typography variant={"h6"}>{configField.definitionName}</Typography>
                    </Box>
                    {Object.keys(configField.properties).length === 0 && (
                        <Box p={2}>
                            <Typography variant={"body2"}>
                                {`No properties in this object. Use `} <Chip size={"small"} sx={{ borderRadius: 1 }} label={<pre>{`{}`}</pre>} />
                                {` in your YAML file.`}
                            </Typography>
                        </Box>
                    )}
                    {Object.keys(configField.properties).map((key, index) => {
                        return <ConfigFieldRow index={index} keyName={key} key={key} configFieldMap={configField.properties} />;
                    })}
                </DarkFlatOutlinedCard>
            );
        }
        if (configField?.arrayType === "object") {
            return (
                <DarkFlatOutlinedCard>
                    <Box pr={2} pl={2} pt={2}>
                        <Typography variant={"h6"}>{configField.arrayItemDefinitionName}</Typography>
                    </Box>
                    {Object.keys(configField.arrayItemType).map((key, index) => {
                        return <ConfigFieldRow index={index} keyName={key} key={key} configFieldMap={configField.arrayItemType} />;
                    })}
                </DarkFlatOutlinedCard>
            );
        }
    };
    return configField.dataType === "oneOf" ? (
        <>{renderExpandedContent()}</>
    ) : (
        <Box key={keyName} mb={2} pr={configField.isOneOf ? 1 : 2} pl={configField.isOneOf ? 1 : 2}>
            <Box width={"100%"}>
                {index !== 0 ? !oneOf ? <LightDivider /> : null : null}
                <Grid container spacing={1} alignItems={"center"} pt={1}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: "monospace",
                                cursor: shouldExpand ? "pointer" : "default",
                                "&:hover": {
                                    color: shouldExpand ? theme.palette.primary.light : undefined,
                                },
                            }}
                            color={"primary"}
                            fontWeight={600}
                            onClick={shouldExpand ? toggleExpanded : undefined}
                        >
                            {keyName}
                        </Typography>
                    </Grid>
                    {shouldExpand && (
                        <Grid>
                            <IconButton disableRipple={true} onClick={toggleExpanded}>
                                {expanded ? <MdExpandLess /> : <MdExpandMore />}
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                <Box>
                    <Box>
                        <b>{formatDataType(configField)}</b>
                    </Box>
                    <Box>{configField.description}</Box>
                    {configField.enum && (
                        <Box>
                            {configField.enum.values.map((v, i) => {
                                return (
                                    <Stack direction={"column"} key={i} ml={2} mb={1} mt={1}>
                                        <Typography fontWeight={600} sx={{ fontFamily: "monospace" }} color={"primary"}>
                                            {v.name}
                                        </Typography>
                                        <Typography>{v.description}</Typography>
                                        <Box mb={1} />
                                        {i !== configField.enum.values.length - 1 && <LightDivider />}
                                    </Stack>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
            {expanded && (
                <Box mt={2} pl={1}>
                    {renderExpandedContent()}
                </Box>
            )}
        </Box>
    );
};

// ======================
// RecipeIcon
// ======================

interface RecipeIconProps {
    recipeId: OperationRecipeID;
}

export const RecipeIcon: React.FC<RecipeIconProps> = (p) => {
    const { recipeId } = p;
    const size = 24;
    if (recipeId === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return <SiAmazonaws size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE) {
        return <SiMicrosoftazure size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE) {
        return <SiNutanix size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE) {
        return <SiMicrosoft size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return <TbDatabaseExport size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION) {
        return <AiOutlineCluster size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION) {
        return <MdOutlineCloud size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT) {
        return <TbReportAnalytics size={size} />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE) {
        return <img src={Ovirt} alt={"Ovirt"} height={size} />;
    }
    return <MdOutlineListAlt size={size} />;
};

enum OperationRecipeCategory {
    COMPUTE,
    LOCAL,
    OTHER,
}
interface OperationRecipeConfig {
    recipeId: OperationRecipeID;
    icon: React.ReactNode;
    description: string;
    recipeKey: string;
    recipeLabel: string;
    category: OperationRecipeCategory;
    hasBuilder?: boolean;
}

const RECIPE_ICON_SIZE = 24;

const RECIPE_CONFIGS: Array<OperationRecipeConfig> = [
    {
        recipeId: OperationRecipeID.MIGRATEOPS_AWS_COMPUTE,
        icon: <SiAmazonaws size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_AWS_COMPUTE),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_AWS_COMPUTE),
        category: OperationRecipeCategory.COMPUTE,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE,
        icon: <SiMicrosoftazure size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE),
        category: OperationRecipeCategory.COMPUTE,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE,
        icon: <SiMicrosoft size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE),
        category: OperationRecipeCategory.COMPUTE,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE,
        icon: <img src={Ovirt} alt={"Ovirt"} height={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE),
        category: OperationRecipeCategory.COMPUTE,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE,
        icon: <SiNutanix size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE),
        category: OperationRecipeCategory.COMPUTE,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION,
        icon: <TbDatabaseExport size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION),
        category: OperationRecipeCategory.LOCAL,
        hasBuilder: true,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION,
        icon: <AiOutlineCluster size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION),
        category: OperationRecipeCategory.LOCAL,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION,
        icon: <MdOutlineCloud size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION),
        category: OperationRecipeCategory.OTHER,
    },
    {
        recipeId: OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT,
        icon: <TbReportAnalytics size={RECIPE_ICON_SIZE} />,
        description: getOperationRecipeDescription(OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT),
        recipeKey: Object.keys(OperationRecipeID).find(
            (k) => OperationRecipeID[k as keyof typeof OperationRecipeID] === OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT
        ),
        recipeLabel: getOperationRecipeLabel(OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT),
        category: OperationRecipeCategory.OTHER,
        hasBuilder: true,
    },
];

export const getRecipeConfig = (recipeId: OperationRecipeID) => {
    return RECIPE_CONFIGS.find((c) => c.recipeId === recipeId);
};
