// Project: GalaxyComplete
// Created: 10/2/20 by sammy
// File: OnboardScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, IconButton, Link, SvgIcon, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { FiLogOut } from "react-icons/fi";
import { LightDivider } from "../../common/misc";
import { useAppServices } from "../app/services";
import { Form, Formik } from "formik";
import { CompleteOnboard } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import * as yup from "yup";
import { FormTextField } from "../../common/form/FormComponents";
import { EULALink, PrivacyPolicyLink, TermsOfServiceLink, useNavigateToHelpCenterKb } from "../../modules/help/HelpCommon";
import { HubUser } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";
import { getIsPrivateEdition } from "./PrivateEditionView";
import { CDSLogo, CDSPrivateEditionLogo } from "../layout/LayoutCommon";
import { useActivateUser, useLogOut } from "./auth_hooks";
import { useGetMyUserInfo } from "../user/user_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import Grid from "@mui/material/Grid2";

// ======================
// OnboardScreen
// ======================

const useOnboardScreenStyles = () => {
    const t = useTheme();
    return {
        root: {
            // background: CirrusGradient,
            //height : '100vh',
            width: "100vw",
            display: "flex",
        },
        card: {},
        login: {
            textAlign: "center",
        },
        center: {
            display: "flex",
            justifyContent: "center",
        },
        logo: {
            maxWidth: "100%",
            width: t.spacing(40),
        },
    };
};

interface OnboardScreenProps {}

export const OnboardScreen: React.FC<OnboardScreenProps> = observer((p) => {
    const getMyUserInfo = useGetMyUserInfo();
    const styles = useOnboardScreenStyles();
    const isPrivateEdition = getIsPrivateEdition();
    const logOut = useLogOut();

    return (
        <Box sx={styles.root} pt={4} pl={2} pr={2} pb={10}>
            <Grid container justifyContent={"center"} direction={"row"}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 10,
                        md: 8,
                        lg: 7,
                        xl: 6,
                    }}
                >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid size={6}>
                            <Box width={"100%"}>
                                {isPrivateEdition ? <CDSPrivateEditionLogo style={styles.logo} /> : <CDSLogo style={styles.logo} size={"large"} />}
                            </Box>
                        </Grid>
                        <Grid>
                            <IconButton onClick={() => logOut.mutate()}>
                                <SvgIcon>
                                    <FiLogOut />
                                </SvgIcon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <br />
                    <LightDivider />
                    <br />
                    <Typography variant={"h2"} paragraph>
                        {"Welcome to Cirrus Data Cloud"}
                    </Typography>
                    <QueryResultWrapper queryResult={getMyUserInfo}>
                        <ProfileCompleteForm userInfo={getMyUserInfo.data?.hubUser} />
                    </QueryResultWrapper>
                </Grid>
            </Grid>
        </Box>
    );
});

// ======================
// ProfileCompleteForm
// ======================
interface ProfileCompleteFormProps {
    userInfo: HubUser.AsObject;
}

const ProfileCompleteForm: React.FC<ProfileCompleteFormProps> = observer((p) => {
    const { dialogService } = useAppServices();
    const nav = useNavigateToHelpCenterKb();
    const activateUser = useActivateUser();

    const initialValues = {
        companyName: p.userInfo.companyName || "",
        firstName: p.userInfo.firstName || "",
        jobTitle: p.userInfo.jobTitle || "",
        lastName: p.userInfo.lastName || "",
        location: "",
        phoneNumber: "",
    };

    const schema = yup.object({
        firstName: yup.string().label("First Name").required().min(1),
        lastName: yup.string().label("Last Name").required().min(1),
        jobTitle: yup.string().label("Job Title").required(),
        companyName: yup.string().label("Company Name").required(),
        location: yup.string().label("Location").required(),
        phoneNumber: yup.string().notRequired().label("Phone Number"),
    });

    const confirmDialogBody = (
        <Typography>
            By continuing, you agree to Cirrus Data's <EULALink />, <TermsOfServiceLink /> and <PrivacyPolicyLink />. Additional information can be obtained by{" "}
            <Link onClick={nav}>visiting our help center</Link>.
        </Typography>
    );
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                const confirm = await dialogService.addConfirmDialog({ renderAdditionalContent: () => confirmDialogBody });
                if (confirm) {
                    const req = new CompleteOnboard.Request()
                        .setFirstName(values.firstName)
                        .setLastName(values.lastName)
                        .setCompanyName(values.companyName)
                        .setJobTitle(values.jobTitle)
                        .setLocation(values.location)
                        .setPhoneNumber(values.phoneNumber);

                    await activateUser.mutate(req);
                }
            }}
        >
            {(props) => (
                <Form>
                    <Typography variant={"h5"}>{"To get started, please tell us a little more about yourself..."}</Typography>
                    <br />
                    <Typography variant={"subtitle1"}>{"What is your name?"}</Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormTextField label={"Your First Name"} name={"firstName"} />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormTextField label={"Your Last Name"} name={"lastName"} />
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant={"subtitle1"}>{"Which company do you represent?"}</Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormTextField label={"Company Name"} name={"companyName"} />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormTextField label={"Job Title"} name={"jobTitle"} />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Typography variant={"subtitle1"}>{"Where are you located?"}</Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <FormTextField label={"Location"} name={"location"} />
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant={"subtitle1"}>{"And optionally, would you like to provide an emergency contact phone number?"}</Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <FormTextField label={"Phone Number"} name={"phoneNumber"} />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid container justifyContent={"center"}>
                        <Button type={"submit"} variant={"contained"} color={"primary"} size={"large"}>
                            {`Done. Let's get started`}
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
});
