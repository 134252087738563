import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";

export const getExampleByRecipe = (recipeId: OperationRecipeID) => {
    if (recipeId === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return LocalDataMigrationExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE) {
        return AzureExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT) {
        return ThinDataReportExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_OVIRT_COMPUTE) {
        return OvirtExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return AwsExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION) {
        return RemoteDataMigrationExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE) {
        return NutanixExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE) {
        return HyperVExample;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION) {
        return MultinodeClusterExample;
    }
    return "";
};

export const AwsExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 973
      migrateops_aws_compute:
        additional_helper_installation_args:
          - -no-cdn
          - -no-check-certificate
          - -force-rpm
          - -no-keep-alive
        compute:
          availability_zone: us-east-1a
          instance_type: t3.medium
          vmname: win2016smu
        destination:
          default_volume_params:
          IOPs: 100
          multi_attach: true
          volume_type: IO2
        keep_source_powered_on: true
        migration:
          key_pair_name: alanc_eng
          qos_level: MODERATE
          security_group_name: ac-https-to-cmc-only
          session_description: Migrate Win2016 OS to AWS with EBS
          subnet_id: subnet-7000183d
          vpc_id: vpc-ab99fcd6
        network:
          subnet_id: subnet-7000183d
          vpc_id: vpc-ab99fcd6
        region: us-east-1
      system_id: 97c0ff36-b581-452f-8c29-ba3e5e4bb353

`;

export const OvirtExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 985
      migrateops_ovirt_compute:
        access_node:
          system_name: ac-alma-8-uefi
        compute:
          cluster: QACL-1
          memory_gib: 4
          vm_name: win2016smb12
        destination:
          default_config:
            sparse: true
            storage_domain: PURE-iSCSI-Datastore-2
        keep_source_powered_on: true
        network:
          vnic_profile: ovirtmgmt
      system_id: be90d862-b32d-4c6f-a9fe-7eae0ee04e26`;

export const NutanixExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 975
      migrateops_nutanix_compute:
        access_node:
          system_name: migrateops-10544-win2016smb
        h2h:
          compression: true
        keep_source_powered_on: true
        network:
          subnet: primary
      system_id: edef0ea0-a2f6-4a89-8922-9d437540aa18`;

export const LocalDataMigrationExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      migrateops_local_data_migration:
        cutover:
          manual_cutover: true
        destination:
          volume_mappings:
          - destination_volume: ${String.raw`\\.\PHYSICALDRIVE3`}
            source: ${String.raw`\\.\PHYSICALDRIVE2`}
        migration:
          qos_level: AGGRESSIVE
          session_description: simple 1GB migration
        source:
          source_volume_names:
          - ${String.raw`\\.\PHYSICALDRIVE2`}
      system_id: ba962e62-6626-4d5e-bceb-5943a8ba2127`;

export const HyperVExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      migrateops_hyperv_compute:
        compute:
          vm_directory: ${String.raw`c:\ClusterStorage\Volume1\AllMyWindowsVMs`}    
        cutover:
          after_cutover:
          - storage_mount_all: {}
          - os_shell:
              script:
              - ${String.raw`c:\Users\Administrator\Downloads\setIPAddress.393.ps1`}
          stop_applications:
          - storage_unmount:
              mountpoint: d
        destination:
          default_config:
            directory: ${String.raw`c:\ClusterStorage\Volume1\AllMyWindowsVMs\Win2016EFI-123`}
        h2h:
          address: 172.23.60.121
          port: 4996
        hyperv_system_name: Win2016-121
        migration:
          auto_resync_interval: 600s
          qos_level: MODERATE
          session_description: Compute To Hyper-V
      system_id: f4b3fec4-c4da-4c18-a0a1-2d563160537a`;

export const RemoteDataMigrationExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 877
      ${String.raw`migrateops_remote_data_migration:
        cutover:
          after_cutover:
          - storage_rescan: {}
          - storage_mount_all: {}
          - os_shell:
              script:
              - $volumeLabel="SQL_Log_L"
              - $newDriveLetter="L"
              - $partition=Get-Volume |Where-Object {$_.FileSystemLabel -like "$VolumeLabel"
                } |get-partition
              - Set-Partition -InputObject $partition -NewDriveLetter $newDriveLetter
              - $volumeLabel="SQL_Data_K"
              - $newDriveLetter="K"
              - $partition=Get-Volume |Where-Object {$_.FileSystemLabel -like "$VolumeLabel"
                } |get-partition
              - Set-Partition -InputObject $partition -NewDriveLetter $newDriveLetter
              - $serverInstance=hostname
              - $databaseName = 'BirthdayDB'
              - $dataFilename="K:\data\BirthdayDB.mdf"
              - $logFilename="L:\log\BirthdayDB_log.ldf"
              - $attachSql = "CREATE DATABASE [$databaseName] ON "
              - $attachSql = $attachSql + "(FILENAME = '$dataFilename'), "
              - $attachSql = $attachSql + "(FILENAME = '$logFilename')"
              - $attachSql = $attachSql + " FOR ATTACH"
              - Invoke-Sqlcmd $attachSql -Database master -QueryTimeout 3600 -ServerInstance
                $serverInstance -ErrorAction Stop
              - $Data=Invoke-Sqlcmd -ServerInstance $serverInstance -Database $databaseName
                -Query "Select Name, DOB from dbo.BDays"
              - write-Output $Data
              - echo "Database $databaseName is attached to $serverInstance and activated."
                > C:\Users\administrator\cmc.log
          before_cutover:
          - os_shell:
              script:
              - $roleName="SQL Server (SQLSERVER128C)"
              - Stop-ClusterGroup $roleName
              - echo "Cluster Role $roleName is stopped and disks are offline." > C:\Users\administrator.CDS\cmc.log
        destination:
          auto_allocate_volumes: true
          auto_prepare_host: true
          system_name: EC2AMAZ-GKBDBU2
        h2h:
          address: 18.220.183.17
        migration:
          auto_resync_interval: 300s
          qos_level: MODERATE
          session_description: Migrate Cluster Node to standalone
        source:
          source_volume_names:
          - \\.\PHYSICALDRIVE2
          - \\.\PHYSICALDRIVE3
      system_id: fcbc51ae-0fac-43f2-8aa3-e8c9b5e09d56`}`;

export const MultinodeClusterExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      ${String.raw`migrateops_local_multinode_cluster_data_migration:
        default_tracker_node_config:
          evict_workload_actions:
          - windows_move_cluster_group:
              resource_group: SQL Server (SQLSERVER128C)
              target_node_name: Win2016-121
          prepare_accept_workload_actions:
          - storage_rescan: {}
          volume_mappings:
          - source: \\\\.\\PHYSICALDRIVE2
          - source: \\\\.\\PHYSICALDRIVE3
          - source: \\\\.\\PHYSICALDRIVE4
        migrator_node:
          after_evict_workload_actions:
          - storage_rescan: {}
          evict_workload_actions:
          - windows_move_cluster_group:
              resource_group: SQL Server (SQLSERVER128C)
              target_node_name: Win2016-122
          post_migration_actions:
          - windows_move_cluster_group:
              resource_group: SQL Server (SQLSERVER128C)
              target_node_name: Win2016-121
          prepare_accept_workload_actions:
          - windows_move_cluster_group:
              resource_group: SQL Server (SQLSERVER128C)
              target_node_name: Win2016-121
          volume_mappings:
          - destination: 'destination: "\\\\\\\\.\\\\PHYSICALDRIVE7" '
            source: 'source: "\\\\\\\\.\\\\PHYSICALDRIVE2"'
          - destination: 'destination: "\\\\\\\\.\\\\PHYSICALDRIVE8" '
            source: 'source: "\\\\\\\\.\\\\PHYSICALDRIVE3"'
          - destination: 'destination: "\\\\\\\\.\\\\PHYSICALDRIVE9" '
            source: 'source: "\\\\\\\\.\\\\PHYSICALDRIVE4"'
        tracker_nodes:
        - system_name: Win2016-122
      system_id: 8510487d-98d2-4be0-b378-3231f39bc570`}`;

export const AzureExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 15
      migrateops_azure_compute:
        compute:
          vm_size: Standard_D2s_v3
        destination:
          default_volume_params:
            storage_type: STANDARD_SSD
        keep_source_powered_on: true
        migration:
          qos_level: MODERATE
          session_description: migrating data for hm-ubuntu20
        network:
          default:
            exclude: true
            network:
              security_group: WIDE-OPEN
              security_group_resource_group: test_group
          mappings:
          - create_public_ip: true
            index: 0
            static_private_ip: 10.0.0.25
        region: eastus
        resource_group: sammy
      system_id: 64ce5e0b-1520-4047-9b30-b4169d4a86b2`;

export const ThinDataReportExample = `operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      migrateops_migration_source_assessment_report:
        exclude_boot_volumes: true
        include_all_systems_in_project: true
      system_id: 318cff4e-e049-4916-b8a2-27f71c90fc20
`;
